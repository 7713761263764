import styled from 'styled-components'

import { Reset } from '@/lib/mixins'

import { ScannerIndicatorWrapper } from '../Scanner/Scanner.styles'

export const ProductWrapper = styled.div`
  margin: 0 auto;
  padding-bottom: 1rem;
`

export const ProductFeaturesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.25rem;
`

export const ProductFeaturesItem = styled.div`
  flex: 1 0 0;
  font-size: 0.85rem;
  letter-spacing: -0.03em;
  min-width: 50%;
  svg {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -0.1em;
    fill: var(--color-green);
    height: 0.9rem;
    width: 0.9rem;
    margin-right: 0.25rem;
  }
`

export const ProductImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  height: 12rem;

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }
`

export const ProductHeader = styled.div`
  margin-bottom: 1rem;
`

export const ProductTitle = styled.h1`
  font-size: 1.3rem;
  margin-bottom: 0.15rem;
`

export const ProductContent = styled.div`
  h2 {
    margin-bottom: 1rem;
  }
  h6 {
    margin-bottom: 0.25rem;
  }
`

export const ProductPrice = styled.h3`
  font-size: 2.25rem;
  color: var(--color-primary);
  letter-spacing: -0.01em;
  margin-bottom: 0;

  sup {
    vertical-align: bottom;
    font-size: 1rem;
    position: relative;
    top: -1rem;
    margin-left: 0.5rem;
    color: var(--color-muted);
  }
`

export const ProductEmpty = styled.div`
  max-width: 14rem;
  margin: 20vh auto;
  text-align: center;
  opacity: 0.5;
`
