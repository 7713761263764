import Icon from '../Icon'
import Loader from '../Loader'
import { ButtonBadge, ButtonIcon, ButtonInner, ButtonWrapper } from './Button.styles'

const Button = ({
  children,
  block,
  variant = 'primary',
  size = 'default',
  onClick,
  badge,
  rounded,
  prependIcon,
  appendIcon,
  isLoading
}) => {
  return (
    <ButtonWrapper block={block} variant={variant} rounded={rounded} onClick={onClick} isLoading={isLoading} size={size}>
      {isLoading && <Loader center={true} />}
      <ButtonInner>
        {prependIcon && (
          <ButtonIcon>
            <Icon name={prependIcon} />
          </ButtonIcon>
        )}
        <span>{children}</span>
        {badge && <ButtonBadge>{badge}</ButtonBadge>}
        {appendIcon && (
          <ButtonIcon>
            <Icon name={appendIcon} />
          </ButtonIcon>
        )}
      </ButtonInner>
    </ButtonWrapper>
  )
}

export default Button
