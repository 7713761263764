import styled, { css } from 'styled-components'

import { Reset, TransitionPrimary } from '@/lib/mixins'

export const NavBarWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: var(--size-nav-bar);
  padding: 0 0.5rem;
`

export const NavBarLogo = styled.div`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 32px;
    object-fit: contain;
    display: block;
  }
`

export const NavBarTool = styled.div`
  flex: 0 0 25%;
  display: flex;

  button {
    ${Reset('button')}
    display: inline-flex;
    width: 2.5rem;
    height: 2.5rem;
    align-items: center;
    justify-content: center;

    svg {
      height: 2rem;
      display: block;
      fill: currentColor;
      ${TransitionPrimary('fill')}
    }
  }

  &:last-child {
    justify-content: flex-end;
  }
`

export const NavBarCart = styled.button`
  position: relative;

  svg {
    height: 1.5rem !important;
  }

  span {
    position: absolute;
    top: 0.1rem;
    right: 0;
    background-color: var(--color-inverted);
    color: var(--color-global);
    border: 2px solid var(--color-primary);
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 0.75rem;
    width: 1.2rem;
    height: 1.2rem;
  }
`
