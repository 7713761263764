import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

import Button from '@/components/Button'
import Icon from '@/components/Icon'
import Panel from '@/components/Panel'
import ProductItem from '@/components/ProductItem'
import products from '@/data/products.json'
import View from '@/layout/View'
import { CURRENCY_SIGN } from '@/lib/helpers'

import useCheckout from '../Checkout/hooks/useCheckout'
import withScanner from '../Scanner/withScanner'
import {
  ProductEmpty,
  ProductFeaturesItem,
  ProductFeaturesList,
  ProductHeader,
  ProductImage,
  ProductPrice,
  ProductTitle,
  ProductWrapper
} from './Product.styles'

const Product = () => {
  const [product, setProduct] = useState({})
  const { id } = useParams()
  const { t } = useTranslation()
  const { addToCart } = useCheckout()
  const navigate = useNavigate()
  const relatedProducts = useMemo(() => products.filter(o => product?.relatedProducts?.includes(o.id)), [product])

  const handleAddToCart = () => {
    addToCart(product)
    navigate('/checkout')
  }

  useEffect(() => {
    setProduct(products.find(o => o.id === id))
  }, [id])

  return (
    <View
      theme="LIGHT"
      middle={true}
      showBack
      footer={
        <Button variant="primary" onClick={handleAddToCart} prependIcon="plus">
          Add to cart
        </Button>
      }
    >
      {product && (
        <ProductWrapper>
          <ProductImage>
            <img src={product.imageUrl} alt="" />
          </ProductImage>
          <ProductHeader>
            <ProductTitle>{product.title}</ProductTitle>
            <ProductPrice>
              {CURRENCY_SIGN}
              {product.price?.toFixed(2)}
              <sup>
                {CURRENCY_SIGN}
                {(product.price / 1.2).toFixed(2)} ex VAT
              </sup>
            </ProductPrice>
            {product.features && (
              <ProductFeaturesList>
                {product.features.map((feature, index) => (
                  <ProductFeaturesItem key={index}>
                    <Icon name="tick" />
                    {feature}
                  </ProductFeaturesItem>
                ))}
              </ProductFeaturesList>
            )}
          </ProductHeader>
          <Panel title={t('overview')}>{product.description}</Panel>
          <Panel title={t('relatedProducts')}>
            {relatedProducts.map(o => (
              <ProductItem key={o.id} {...o} />
            ))}
          </Panel>
        </ProductWrapper>
      )}
      {!product && <ProductEmpty>{t('cantFindProduct')}</ProductEmpty>}
    </View>
  )
}

export default withScanner(Product)
