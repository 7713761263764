import styled, { css } from 'styled-components'

import { Cover, Reset, TransitionPrimary } from '@/lib/mixins'

export const PaymentWrapper = styled.div`
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const PaymentTotal = styled.div`
  dl {
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
  }
  dt,
  dd {
    text-align: center;
    display: block;
  }

  dt {
    font-weight: 500;
    color: var(--color-dark);
  }

  dd {
    font-size: 3rem;
    line-height: 1;
    color: var(--color-primary);
  }
`

export const PaymentFooter = styled.div`
  margin-top: auto;
  padding-top: 1.25rem;
`

export const PaymentOptions = styled.div`
  margin-bottom: 1rem;
`

export const PaymentOptionIcon = styled.div`
  position: relative;
  margin-right: 1.5rem;

  svg {
    width: 2.25rem;
    height: 2.25rem;
    display: block;
    fill: currentColor;
    ${TransitionPrimary('fill')}
  }
`

export const PaymentOptionContent = styled.div`
  position: relative;
  z-index: 1;
  h2 {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
`

export const PaymentOptionWrapper = styled.button`
  ${Reset('button')}
  width: 100%;
  position: relative;
  display: flex;
  height: 6.5rem;
  align-items: center;
  border: 1px solid var(--color-line);
  padding: 0 2rem;
  ${TransitionPrimary('color')}

  &:before {
    ${Cover('-1px')}
    background-color: var(--color-primary);
    ${TransitionPrimary('transform, opacity, visibility')}
    visibility: hidden;
    opacity: 0;
    transform: scale(0.95);
  }

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  ${props =>
    props.active &&
    css`
      color: var(--color-inverted);
      &:before {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
      }
    `};
`

export const PaymentVendor = styled.div`
  margin-bottom: 1.5rem;
  text-align: center;
  margin-top: auto;

  span {
    display: block;
    margin-bottom: 0.25rem;
    font-size: 0.75rem;
    color: var(--color-muted);
    font-weight: 500;
  }
  img {
    display: inline-block;
    max-height: 2rem;
    max-width: 6rem;
    object-fit: contain;
    margin-left: 0.25rem;
    fill: var(--color-primary);
  }
`
