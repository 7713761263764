import styled from 'styled-components'

export const ProductItemWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid var(--color-line);
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
`
export const ProductItemImage = styled.div`
  flex: 0 0 5rem;
  img {
    display: block;
  }
`
export const ProductItemContent = styled.div`
  flex: 1 1 auto;
  padding-left: 1.5rem;
`
export const ProductItemTitle = styled.h3`
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`
export const ProductItemPrice = styled.span`
  font-weight: 500;
  font-size: 1.1rem;
  color: var(--color-primary);
`
