import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Button'
import Icon from '@/components/Icon'
import API from '@/lib/api'

import useCheckout from '../../hooks/useCheckout'
import Step from '../Step/Step'
import { ConfirmationContent, ConfirmationFooter, ConfirmationIcon, ConfirmationVendor } from './Confirmation.styles'
import ConfirmationReceiptModal from './ConfirmationReceiptModal'

const Confirmation = () => {
  const { t } = useTranslation()
  const { cart, paymentError } = useCheckout()
  const [isReceiptLoading, setIsReceiptLoading] = useState(false)
  const [onlineReceipt, setOnlineReceipt] = useState(null)

  const printConfirmation = () => {
    try {
      const data = cart.map(o => ({ name: o.title, amount: o.price * o.qty }))
      window.BtDevice.printInvoice(JSON.stringify(data))
    } catch (error) {
      console.error('[DEVICE] printInvoice error', error)
    }
  }

  const showOnlineRceipt = () => {
    setIsReceiptLoading(true)
    API.generateOnlineReceipt({
      items: cart.map(o => ({
        name: o.title,
        quantity: o.qty,
        price: o.price
      }))
    })
      .then(response => {
        setIsReceiptLoading(false)
        setOnlineReceipt(response.data)
      })
      .catch(error => {
        console.error('[API] generateOnlineReceipt error', error)
        setIsReceiptLoading(false)
      })
  }

  return (
    <Step>
      <ConfirmationContent>
        {/* Success */}
        {!paymentError && (
          <>
            <ConfirmationIcon>
              <svg viewBox="0 0 188 142">
                <motion.polyline
                  strokeWidth="14"
                  initial={{ pathLength: 0 }}
                  animate={{ pathLength: 1 }}
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  transition={{
                    delay: 0,
                    duration: 0.75
                  }}
                  fill="none"
                  points="20 74 73 126 170 20"
                ></motion.polyline>
              </svg>
            </ConfirmationIcon>
            <h1>{t('paymentConfirmationTitle')}</h1>
            <p>{t('paymentConfirmationDescription')}</p>
          </>
        )}
        {/* Error */}
        {paymentError && (
          <>
            <ConfirmationIcon>
              <svg viewBox="0 0 138 138">
                <g stroke="#E43232" strokeWidth="14">
                  <motion.line
                    x1="13"
                    y1="125"
                    x2="125"
                    y2="13"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    transition={{
                      delay: 0,
                      duration: 0.75
                    }}
                  ></motion.line>
                  <motion.line
                    x1="13"
                    y1="13"
                    x2="125"
                    y2="125"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{
                      delay: 0.25,
                      duration: 0.75
                    }}
                  ></motion.line>
                </g>
              </svg>
            </ConfirmationIcon>
            <h1>{t('paymentErrorTitle')}</h1>
            <p>{paymentError}</p>
          </>
        )}
      </ConfirmationContent>
      <ConfirmationFooter>
        <ConfirmationVendor>
          <span>Powered by</span>
          <Icon name="berg" />
        </ConfirmationVendor>
        {!paymentError && (
          <>
            <Button block={true} variant="secondary" onClick={() => printConfirmation()}>
              {t('printConfirmation')}
            </Button>
            <Button
              block={true}
              variant="secondary"
              isLoading={isReceiptLoading}
              transparent
              icon="qr-code"
              onClick={() => showOnlineRceipt()}
            >
              {t('showOnlineRceipt')}
            </Button>
          </>
        )}
      </ConfirmationFooter>
      <AnimatePresence>
        {onlineReceipt && <ConfirmationReceiptModal image={onlineReceipt} onClose={() => setOnlineReceipt(null)} />}
      </AnimatePresence>
    </Step>
  )
}

export default Confirmation
