import styled, { css } from 'styled-components'

import { TransitionPrimary } from '@/lib/mixins'

export const PanelWrapper = styled.div`
  border-bottom: 1px solid var(--color-line);
`

export const PanelHeader = styled.div`
  padding: 1.25rem 0;
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const PanelTitle = styled.h3`
  font-weight: 600;
  font-size: 1.15rem;
  margin-bottom: 0;
`

export const PanelIndicator = styled.span`
  display: inline-flex;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  ${TransitionPrimary('background')}

  svg {
    fill: var(--color-primary);
    width: 50%;
    height: 50%;
    display: block;
    ${TransitionPrimary('fill, transform')}
  }

  ${props =>
    props.isOpened &&
    css`
      svg {
        fill: var(--color-primary);
        transform: rotate(180deg);
      }
    `};
`

export const PanelContent = styled.div`
  border-top: 2px solid var(--color-body);
  padding: 0 0 1.75rem 0;

  dl {
    padding: 0;
    margin: 0 0 0.5rem 0;
    display: flex;

    dt {
      flex: 0 0 30%;
      text-align: right;
      margin-right: 1rem;
      color: var(--color-muted);
      opacity: 1;
    }

    dd {
      flex: 1 1 auto;
      font-weight: 500;
    }
  }

  > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
`
