import styled, { css } from 'styled-components'

import { Reset, TransitionPrimary } from '@/lib/mixins'

export const ButtonBadge = styled.span`
  display: inline-block;
  padding: 0.25em 0.5em;
  letter-spacing: 0;
  font-size: 0.9em;
  background-color: var(--color-inverted);
  border-radius: 1em;
  color: var(--color-global);
  margin-left: 0.5rem;
`

export const ButtonIcon = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 0.75rem;
  height: 0.75rem;

  &:first-child {
    margin-right: 0.75rem;
  }
  &:last-child {
    margin-left: 0.75rem;
  }

  svg {
    fill: currentColor;
    width: 100%;
    height: 100%;
    display: block;
    ${TransitionPrimary('fill, transform')}
  }
`

export const ButtonInner = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  ${TransitionPrimary('opacity, visibility')}
`

export const ButtonWrapper = styled.button`
  ${Reset('button')}
  ${TransitionPrimary()}
  font-weight: 600;
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  border: 1px solid transparent;
  font-weight: 400;
  height: 3.25rem;
  padding: 0 1.75rem;
  font-size: 1.2rem;
  line-height: 1;

  ${props =>
    props.size === 'lg' &&
    css`
      height: 4rem;
    `};

  ${props =>
    props.variant === 'red' &&
    css`
      background-color: var(--color-red);
      color: var(--color-inverted);
    `};

  ${props =>
    props.variant === 'primary' &&
    css`
      background-color: var(--color-primary);
      color: var(--color-inverted);
    `};

  ${props =>
    props.variant === 'inverted' &&
    css`
      background-color: var(--color-inverted);
      color: var(--color-primary);

      ${ButtonBadge} {
        background-color: var(--color-primary);
        color: var(--color-inverted);
      }
    `};

  ${props =>
    props.variant === 'dark' &&
    css`
      background-color: var(--color-global);
      color: var(--color-inverted);
    `};

  ${props =>
    props.variant === 'secondary' &&
    css`
      color: var(--color-primary);
      border: 1px solid var(--color-primary);
    `};

  ${props =>
    props.variant === 'secondary-inverted' &&
    css`
      color: var(--color-inverted);
      border: 1px solid var(--color-inverted);
    `};

  ${props =>
    props.block &&
    css`
      width: 100%;
      display: flex;

      & + ${ButtonWrapper} {
        margin-top: 0.5rem;
      }
    `};

  ${props =>
    props.rounded &&
    css`
      background: var(--color-body);
      border: 1px solid var(--color-line);
      border-radius: 2rem;
      height: 2rem;
      font-size: 0.65rem;
      padding: 0 0.75rem;
      letter-spacing: 0.1em;
      color: var(--color-global);
    `};

  ${props =>
    props.isLoading &&
    css`
      ${ButtonInner} {
        opacity: 0;
        visibility: hidden;
      }
    `};
`
