import { useTranslation } from 'react-i18next'

import ProductItem from '@/components/ProductItem'
import products from '@/data/products.json'
import View from '@/layout/View'

import withScanner from '../Scanner/withScanner'

const Listing = () => {
  const { t } = useTranslation()

  return (
    <View theme="LIGHT" showBack>
      <div>
        <h2>Featured Products</h2>
        {products.map(o => (
          <ProductItem key={o.id} {...o} />
        ))}
      </div>
    </View>
  )
}

export default withScanner(Listing)
