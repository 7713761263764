export const breakpoints = {
  xxs: 0,
  xs: 575,
  sm: 767,
  md: 991,
  lg: 1199,
  xl: 1439,
  xxl: 1829
}

const theme = {
  font: {
    primary: 'DINNextRoundedLTW01, sans-serif',
    secondary: 'Roboto Slab, sans-serif'
  },
  color: {
    body: '#ffffff',
    global: '#000000',
    inverted: '#ffffff',
    muted: 'rgba(0,0,0,0.5)',
    mutedLight: 'rgba(0,0,0,0.4)',
    mutedInverted: 'rgba(255,255,255,0.5)',
    green: '#0AB500',
    purple: '#CCBBFF',
    purpleTransparent: 'rgba(204,187,255,0.3)',
    light: '#EFF3F8',
    dark: '#000329',
    primary: '#F95108',
    line: '#DEDEDE',
    lineInverted: 'rgba(255,255,255,0.15)',
    red: '#C91900',
    darkOverlay: 'rgba(0,0,0,0.15)'
  },
  radius: {
    sm: '0.3rem',
    md: '0.4rem',
    lg: '0.6rem',
    xl: '0.8rem'
  },
  control: {
    sm: '2.2rem',
    md: '2.75rem',
    lg: '3.2rem'
  },
  grid: {
    outerMargin: '16px',
    gutterWidth: '16px'
  },
  spacing: {
    desktop: {
      md: '3rem',
      sm: '1.5rem'
    },
    mobile: {
      md: '2rem',
      sm: '1.25rem'
    }
  },
  size: {
    mobileNavBar: '64px',
    desktopNav: {
      collapsed: '5rem',
      extended: '17rem'
    },
    topBar: {
      mobile: '56px',
      desktop: '70px'
    },
    thumbnail: {
      mobile: '2.75rem',
      desktop: '3.25rem'
    }
  },
  shadow: {
    sm: '0 2px 12px 0 rgba(0,0,0,0.125)',
    md: '0 2px 24px 0 rgba(0,0,0,0.125)',
    lg: '0 2px 90px 0 rgba(0,0,0,0.02)'
  }
}

export default theme
